import { Text, View, StyleSheet } from "react-native";
import { Surface } from "react-native-paper";
import { parseUKAddress } from "utilities/strings";

export const AddressCard = ({ address }) => {
  if (!address) {
    return null;
  }

  var ukaddress = parseUKAddress(address);

  return (
    <Surface style={styles.addressBox} elevation={1}>
      <View style={styles.addressBoxText}>
        <Text>
          {ukaddress.buildingNumber} {ukaddress.streetName}
        </Text>
        <Text>{ukaddress.townCity}</Text>
        <Text>{ukaddress.postcode}</Text>
      </View>
    </Surface>
  );
};

const styles = StyleSheet.create({
  addressBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    marginTop: 16,
    padding: 16,
    borderRadius: 8,
  },
  addressBoxIcon: {
    marginRight: 8,
  },
  addressBoxText: {
    flex: 1,
  },
});
