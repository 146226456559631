import { format, formatDistance } from "date-fns";

export const humanReadableDateTime = (datetime: string) => {
  try {
    var date = new Date(Date.parse(datetime));
    if (date.getFullYear() === new Date().getFullYear()) {
      return format(date, "MMMM do 'at' h:mma");
    } else {
      return format(date, "MMMM do, yyyy 'at' h:mma");
    }
  } catch (e) {
    return "";
  }
};

export const timeUntil = (datetime: string) => {
  try {
    return formatDistance(new Date(Date.parse(datetime)), new Date(), {
      addSuffix: true,
    });
  } catch (e) {
    return "";
  }
};


export const humanReadableDateTimeFromSeconds = (seconds: number) => {
  try {
    var date = new Date(seconds * 1000);
    if (date.getFullYear() === new Date().getFullYear()) {
      return format(date, "MMMM do 'at' h:mma");
    } else {
      return format(new Date(seconds * 1000), "MMMM do, yyyy 'at' h:mma");
    }
  } catch (e) {
    return "";
  }
};

export const timeUntilSeconds = (seconds: number) => {
  try {
    return formatDistance(new Date(seconds * 1000), new Date(), {
      addSuffix: true,
    });
  } catch (e) {
    return "";
  }
};
