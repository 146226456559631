import React from "react";
import "./index.css";
import { AppRegistry } from "react-native";
import appConfig from "./app.json";
import App from "./App";
import Event from "./screens/Event";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";


const client = new ApolloClient({
  uri: "https://cms.todoo.uk/graphql",
  cache: new InMemoryCache(),
});

const Stack = createNativeStackNavigator();
const rootTag = document.getElementById("root");

AppRegistry.registerComponent(appConfig.expo.name, () => () => {
  const linking = {
    prefixes: ["https://todoo.uk", "todoo://"],
    config: {
      screens: {
        Home: "",
        Event: "event/:eventId",
      },
    },
  };

  return (
    <ApolloProvider client={client}>
      <NavigationContainer linking={linking}>
        <Stack.Navigator>
          <Stack.Screen name="Home" component={App} />
          <Stack.Screen name="Event" component={Event} />
        </Stack.Navigator>
      </NavigationContainer>
    </ApolloProvider>
  );
});

AppRegistry.runApplication(appConfig.expo.name, { initialProps: {}, rootTag });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
