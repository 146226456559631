import {
  View,
  Image,
  StyleSheet,
} from "react-native";
import { 
  Text,
  Icon,
  ActivityIndicator
} from "react-native-paper";
import { useState } from "react";
import EventAppMenu from "components/AppMenu/Event";
import { useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "types/navigation";
import { imageAsset } from "utilities/assets";
import { humanReadableDateTime } from "utilities/time";
import { AddressCard } from "components/Card/AddressCard";
import { createDirectus, graphql, authentication } from "@directus/sdk";

const client = createDirectus("https://cms.todoo.uk").with(graphql()).with(authentication());
client.setToken("I0-Rpji75Mk1zNIs-WBL8fRlC4Ay1dKx");

const EVENT_QUERY = `
query GetEvents ($id: ID!) {
  events_by_id (id: $id) {
    id
    status
    name
    description
    image {
      id
      storage
      filename_download
    }
    start_time
    end_time
    location
    short_address
    address
    tickets_required
    minimum_price
    maximum_price
    directions
    event_type {
      id
      name
    }
    organization {
      id
      name
    }
    safety_notice
    safety_features {
      safety_features_id {
        id
        status
        icon
        name
        description
      }
    }
    user_created {
      id
      first_name
      phone
      email
    }
    website
  }
}`;


export default function App(props: RootStackParamList["Event"]) {
  var navigation = useNavigation();
  var { id, personalView, name, image } = props.route.params;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const response = client.query(EVENT_QUERY, { id: props.route.params.eventId }).then((value)=>{
    setLoading(false);
    setData(value);
  }).catch((e)=>{
    setLoading(false);
    setError(true);
  });

  if (loading) return (<><EventAppMenu name="Loading..." /><ActivityIndicator /></>);
  if (error) return (<><EventAppMenu name="Error!" /><Text variant="titleLarge">Failed to load event...</Text></>);


  return (
    <View style={styles.screen}>
      <EventAppMenu name="Event" canEdit={personalView} eventData={{
        ...data.events_by_id,
        safety_features: data.events_by_id.safety_features.map(item=>{
          return {
            id: item.safety_features_id.id,
            status: item.safety_features_id.status,
            icon: item.safety_features_id.icon,
            name: item.safety_features_id.name
          }
        }),
      }} />
      {data.events_by_id.image ? (
        <Image
          source={{ uri: imageAsset(data.events_by_id.image.id) }}
          style={styles.image}
          resizeMode="cover"
        />
      ) : null}

      <View style={{ margin: 16 }}>

        <Text style={styles.datetime}>
          {humanReadableDateTime(data.events_by_id.start_time)}
        </Text>

        {data.events_by_id.description && <Text style={{ marginTop: 10 }}>{data.events_by_id.description}</Text>}

        <AddressCard address={data.events_by_id.address} />

        {data.events_by_id.directions &&
          <>
            <Text variant="titleLarge" style={{ marginTop: 16 }}>Directions: </Text>
            <Text style={styles.textSection}>{data.events_by_id.directions}</Text>
          </>
        }


        <Text variant="titleLarge" style={{ marginTop: 16 }}>Contact Details: </Text>
        {data.events_by_id.user_created && data.events_by_id.user_created.phone ? (
          <View style={styles.contactBox}>
            <View style={styles.contactBoxIcon}>
              <Icon source={require("assets/icons/phone.png")} size={24} />
            </View>
            <View style={styles.contactBoxText}>
              <Text>{data.events_by_id.user_created.phone}</Text>
            </View>
          </View>
        ) : (
          <></>
        )}
        {data.events_by_id.user_created && data.events_by_id.user_created.email ? (
          <View style={styles.contactBox}>
            <View style={styles.contactBoxIcon}>
              <Icon source={require("assets/icons/at.png")} size={24} />
            </View>
            <View style={styles.contactBoxText}>
              <Text>{data.events_by_id.user_created ? data.events_by_id.user_created.email : ""}</Text>
            </View>
          </View>
        ) : (
          <></>
        )}
        <View style={styles.contactBox}>
          <View style={styles.contactBoxIcon}>
            <Icon source={require("assets/icons/web.png")} size={24} />
          </View>
          <View style={styles.contactBoxText}>
            <Text>usernotfound.com</Text>
          </View>
        </View>
        {data.events_by_id.user_created && data.events_by_id.user_created.phone ? (
          <View style={styles.contactBox}>
            <View style={styles.contactBoxIcon}>
              <Icon source={require("assets/icons/whatsapp.png")} size={24} />
            </View>
            <View style={styles.contactBoxText}>
              <Text>{data.events_by_id.user_created ? data.events_by_id.user_created.phone : ""}</Text>
            </View>
          </View>
        ) : (
          <></>
        )}


        {data.events_by_id.safety_features.length > 0 &&
        <Text variant="titleLarge" style={{ marginTop: 16 }}>Safety Features: </Text>  }
        {data.events_by_id.safety_features.map((item, index)=>(
          <View style={styles.contactBox} key={index}>
            <View style={styles.contactBoxIcon}>
              <Icon source={require(`assets/icons/${item.safety_features_id.icon}.png`)} size={24} />
            </View>
            <View style={styles.contactBoxText}>
              <Text>{item.safety_features_id.name}</Text>
            </View>
          </View>
        ))}  

        {data.events_by_id.safety_notice && <>
          <Text variant="titleLarge" style={{ marginTop: 16 }}>Safety Notice: </Text>
          <Text style={styles.textSection}>
            {data.events_by_id.safety_notice}
          </Text>
        </>}


        { data.events_by_id.safety_features.length <= 0 && !data.events_by_id.safety_notice ?
          <Text variant="titleLarge">
            No Security Information...
          </Text> : <></>
        }

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  image: {
    width: "100%",
    aspectRatio: 1.66,
  },
  datetime: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#F45F6A",
  },
  textSection: {
    marginTop: 5,
    marginBottom: 16,
  },
  contactBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    borderRadius: 8,
    marginBottom: 32,
  },
  contactBoxIcon: {
    marginRight: 8,
  },
  contactBoxText: {
    flex: 1,
  },
});
