import { Appbar, Text } from "react-native-paper";

type Props = {
  name: string;
  canEdit?: boolean;
  eventData?: object;
};

const EventAppMenu = ({ name }: Props) => {
  return (
    <Appbar.Header elevated>
      <Appbar.Content
        title={<Text style={{ fontWeight: "bold" }}>{name}</Text>}
        style={{ alignItems: "center" }}
      />
    </Appbar.Header>
  );
};

export default EventAppMenu;
