import React from "react";
import { Text, View, StyleSheet, Image, Dimensions } from "react-native";
import { ReactComponent as AppStore } from "./assets/app-store-badge.svg";
import { ReactComponent as PlayStore } from "./assets/google-play-badge.svg";

const logo = require("./assets/logo.png");

const windowHeight = Dimensions.get("window").height;

function App() {
  return (
    <div style={{ textAlign: "center", minHeight: "100vh" }}>
      <View style={styles.container}>
        <Image source={logo} style={styles.logo} resizeMode="cover" />
        <Text style={styles.title}>ToDoo</Text>
        <View style={styles.buttonGrid}>
          <PlayStore style={styles.storeBadge} />
          <AppStore style={styles.storeBadge} />
        </View>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    textAlign: "center",
    backgroundColor: "#fff",
    minHeight: windowHeight,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
    color: "white",
  },
  logo: { width: 200, height: 200 },
  title: {
    color: "black",
    fontSize: 36,
  },
  link: {
    color: "#61dafb",
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  storeBadge: {
    height: 150,
  },
});

export default App;
