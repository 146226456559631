type UKAddress = {
  buildingNumber?: string;
  streetName?: string;
  locality?: string;
  townCity?: string;
  county?: string;
  postcode?: string;
};

export const parseUKAddress = (address: string): UKAddress => {
  const addressComponents: UKAddress = {};

  // Regular expression to extract postcode
  const postcodeRegex = /[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}/i;
  const postcodeMatch = address.match(postcodeRegex);
  if (postcodeMatch) {
    addressComponents.postcode = postcodeMatch[0];
    address = address.replace(postcodeRegex, "").trim(); // Remove postcode from address
  }

  // Extract building number (typically the first number in the address)
  const buildingNumberRegex = /^\d+\b/;
  const buildingNumberMatch = address.match(buildingNumberRegex);
  if (buildingNumberMatch) {
    addressComponents.buildingNumber = buildingNumberMatch[0];
    address = address.replace(buildingNumberRegex, "").trim(); // Remove building number
  }

  // Remaining address parts (heuristic)
  const parts = address.split(",");
  switch (parts.length) {
    case 4:
      addressComponents.streetName = parts[0].trim();
      addressComponents.locality = parts[1].trim();
      addressComponents.townCity = parts[2].trim();
      addressComponents.county = parts[3].trim();
      break;
    case 3:
      addressComponents.streetName = parts[0].trim();
      addressComponents.townCity = parts[1].trim();
      addressComponents.county = parts[2].trim();
      break;
    case 2:
      addressComponents.streetName = parts[0].trim();
      addressComponents.townCity = parts[1].trim();
      break;
    case 1:
      addressComponents.streetName = parts[0].trim();
      break;
    default:
      console.warn("Unexpected address format");
  }

  return addressComponents;
};

export const hashString = (message: string) => {
  let hash = 5381;
  for (let i = 0; i < message.length; i++) {
    hash = (hash * 33) ^ message.charCodeAt(i);
  }
  return (hash >>> 0).toString(16);
};
